import { firestore } from "@/firebase"
import { Timestamp, collection, doc } from "firebase/firestore"
import { FirestoreClass } from "./FirestoreClass"

export enum LogType {
    docChanged = "docChanged",
    docCreated = "docCreated",
    docDeleted = "docDeleted",
    epCaseCreated = "epCaseCreated",
    epCaseUpdated = "epCaseUpdated",
    forumCaseCreated = "forumCaseCreated",
    forumCaseUpdated = "forumCaseUpdated",
    resendEmail = "resendEmail",
    sendEmail = "sendEmail"
}

export enum Frontend {
    financer = "financer",
    dispatcher = "dispatcher",
    kundenportal = "kundenportal"
}

export class Log extends FirestoreClass {
    frontend: Frontend = Frontend.financer
    username: string = ""
    userid: string = ""
    companyid: string = ""
    docRefPath: string = ""
    newData: object = {}
    oldData: object = {}
    timestamp: Date = new Date()
    logType: LogType = LogType.docChanged

    constructor(id: string = "") {
        super(id)
        this.timestamp = new Date()
    }

    override fromData(data: Record<string, unknown>): void {

        this.frontend = data.frontend as Frontend || Frontend.financer
        this.username = data.username as string || ""
        this.userid = data.userid as string || ""
        this.companyid = data.companyid as string || ""
        this.docRefPath = data.docRefPath as string || ""
        this.newData = data.newData as Record<string, unknown> || {}
        this.oldData = data.oldData as Record<string, unknown> || {}
        this.logType = data.logType as LogType || LogType.docChanged


        if (data.timestamp) {
            this.timestamp = (data.timestamp as Timestamp).toDate()
        } else {
            this.timestamp = new Date("2000-01-01")
        }

    }

    toData() {
        return {
            frontend: this.frontend,
            username: this.username,
            userid: this.userid,
            companyid: this.companyid,
            docRefPath: this.docRefPath,
            newData: this.newData,
            oldData: this.oldData,
            timestamp: this.timestamp,
            logType: this.logType
        }
    }

    get ref() {
        return doc(this.collectionRef, this.id)
    }
    get collectionRef() {
        return collection(firestore, "Log")
    }

}