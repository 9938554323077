import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pt-20 print:pt-0 flex" }
const _hoisted_2 = {
  key: 0,
  class: "w-full fixed top-0 left-0 h-full z-40"
}
const _hoisted_3 = { class: "absolute flex bg-black bg-opacity-5 top-0 left-0 bottom-0 right-0 overflow-hidden items-center justify-center" }
const _hoisted_4 = { class: "flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_navigation = _resolveComponent("app-navigation")!
  const _component_app_notification = _resolveComponent("app-notification")!
  const _component_snackbar_item = _resolveComponent("snackbar-item")!
  const _component_icon_item = _resolveComponent("icon-item")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    id: "vue-app",
    class: _normalizeClass([_ctx.appClassList, ""])
  }, [
    _createVNode(_component_app_navigation),
    _createVNode(_component_app_notification),
    _createVNode(_component_snackbar_item, {
      modelValue: _ctx.showSnackbar,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showSnackbar) = $event)),
      color: _ctx.snackbarColor
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.snackbarMessage), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "color"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_icon_item, {
                  class: "fill-primary-normal animate-spin",
                  path: _ctx.mdiLoading,
                  size: "64"
                }, null, 8, ["path"])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isAuthenticating)
        ? (_openBlock(), _createBlock(_component_router_view, {
            key: 1,
            class: _normalizeClass(_ctx.contentClassList)
          }, null, 8, ["class"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}