import { firestore } from "@/firebase"
import { collection, doc } from "firebase/firestore"
import { FirestoreClass } from "./FirestoreClass"


export class IncommingCall extends FirestoreClass {
    incommingNumber: string = ""
    internalNumber: string = ""
    state: string = ""
    done:boolean = false
    userid: string = ""
    uniqueid: string = ""

    constructor(id: string = "") {
        super(id)
    }

    override toData() {
        const data: Record<string, Partial<unknown>> = {}
        data.incommingNumber = this.incommingNumber
        data.internalNumber = this.internalNumber
        data.state = this.state
        data.done = this.done
        data.userid = this.userid
        data.uniqueid = this.uniqueid


        return data
    }
    override fromData(data: Record<string, unknown>): void {
        
        if (data.incommingNumber) {
            this.incommingNumber = data.incommingNumber as string
        }
        if (data.internalNumber) {
            this.internalNumber = data.internalNumber as string
        }
        if (data.state) {
            this.state = data.state as string
        }
        if (data.done) {
            this.done = data.done as boolean
        }
        if (data.userid) {
            this.userid = data.userid as string
        }
        if (data.uniqueid) {
            this.uniqueid = data.uniqueid as string
        }
    }

    override get ref() {
        return doc(this.collectionRef, this.id)
    }
    override get collectionRef() {
        return collection(firestore, "incomming_call")
    }
}