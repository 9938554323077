import { collection, CollectionReference, doc, DocumentData, DocumentReference } from "firebase/firestore"
import { Company } from "./Company"
import { FirestoreClass } from "./FirestoreClass"

export class Address {
    street: string = ""
    number: string = ""
    postcode: string = ""
    location: string = ""
    room: string = ""


    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.street = data.street as string
            this.number = data.number as string
            this.postcode = data.postcode as string
            this.location = data.location as string
            this.room = data.room as string || ""

        }
    }
    toData() {
        return {
            street: this.street,
            number: this.number,
            postcode: this.postcode,
            location: this.location,
            room: this.room

        }
    }
}

export class HeadlineItem {
    order: number = -1
    questType: string =  "documentUpload"
    title: string = ""
    toComplete: boolean = true
    rememberDaily: boolean = true
    done: boolean = false
}
export class Headline {
    id: number = -1
    items: HeadlineItem[] = []
    order: number = 1
}
export class Category {
    id: number = -1
    headlines: Headline[] = []
    title: string = ""
    order: number = 1
}

export enum CustomerState {
    status_offen = "status_offen",
    status_terminiert = "status_terminiert",
    status_kein_interesse = "status_kein_interesse",
    status_vor_zurueckgestellt = "status_vor_zurueckgestellt",
    status_offen_ne = "status_offen_ne"
}
export class Customer extends FirestoreClass {
    private _company: Company

    gender: string = ""
    firstName: string = ""
    lastName: string = ""
    email: string = ""
    phone: string = ""
    mobile: string = ""

    note: string = ""

    address: Address = new Address()
    companyName: string = ""
    owner :string = ""
    users: string[] = []
    categories : Category[] = []
    salutation:string = ""
    customerPortalActive: boolean = false
    payoutActive: boolean = false

    hasapartner: boolean = false
    partnerSalutation: string = ""
    partnerFirstName: string = ""
    partnerLastName: string = ""
    source: string = ""
    info: string = ""
    internalInfo: string = ""

    website: string = ""
    imprint: string = ""
    businessArea: string = ""
    customerType: string = "1"

    state: CustomerState = CustomerState.status_offen
    userid: string = ""

    constructor(company: Company, owner: string, id: string = "") {
        super(id)
        this._company = company
        this.owner = owner
        this.users = [owner]
    }

    get company() {
        return this._company
    }

    get fullName() {
        return `${this.firstName} ${this.lastName}`.trim()
    }

    override fromData(data: Record<string, unknown>): void {
        this.gender = data?.gender as string || ""
        this.firstName = data?.firstName as string || ""
        this.lastName = data?.lastName as string || ""
        this.email = data?.email as string || ""
        this.phone = data?.phone as string || ""
        this.note = data?.note as string || ""
        this.mobile = data?.mobile as string || ""
        this.info = data?.info as string || ""
        this.internalInfo = data?.internalInfo as string || ""
        
        this.website = data?.website as string || ""
        this.imprint = data?.imprint as string || ""
        this.businessArea = data?.businessArea as string || ""
        this.customerType = data?.customerType as string || "1"
        this.state = data?.state as CustomerState || CustomerState.status_offen
        this.userid = data?.userid as string || "1"
        
        if (data.address) {
            this.address = new Address(data.address as Record<string, unknown>)
        }
        if (data.companyName) {
            this.companyName = data.companyName as string
        }
        if (data.users) {
            this.users = data.users as string[]
        }
        if (data.categories) {
            this.categories = data.categories as Category[]
        }
        if (data.salutation) {
            this.salutation = data.salutation as string
        }
        if (data.customerPortalActive) {
            this.customerPortalActive = data.customerPortalActive as boolean
        }
        if (data.payoutActive) {
            this.payoutActive = data.payoutActive as boolean
        }
        if (data.hasapartner) {
            this.hasapartner = data.hasapartner as boolean
        }
        if (data.partnerSalutation) {
            this.partnerSalutation = data.partnerSalutation as string
        }
        if (data.partnerFirstName) {
            this.partnerFirstName = data.partnerFirstName as string
        }
        if (data.partnerLastName) {
            this.partnerLastName = data.partnerLastName as string
        }


        if (data.source) {
            this.source = data.source as string
        }

        if (data.owner) {
            this.owner = data.owner as string
        }
    }
    override toData() {
        const data: Record<string, unknown> = {}
        data.gender = this.gender
        data.firstName = this.firstName
        data.lastName = this.lastName
        data.email = this.email
        data.phone = this.phone
        data.mobile = this.mobile
        data.note = this.note
        data.address = this.address.toData()
        data.companyName = this.companyName
        data.owner = this.owner
        data.users = this.users
        data.categories = this.categories
        data.salutation = this.salutation
        data.customerPortalActive = this.customerPortalActive
        data.payoutActive = this.payoutActive
        data.hasapartner = this.hasapartner
        data.source = this.source

        data.partnerSalutation = this.partnerSalutation
        data.partnerFirstName = this.partnerFirstName
        data.partnerLastName = this.partnerLastName
        data.info = this.info
        data.internalInfo = this.internalInfo

        data.website = this.website
        data.imprint = this.imprint
        data.businessArea = this.businessArea
        data.customerType = this.customerType
        data.state = this.state
        data.userid = this.userid

        data._searchIndex = [
            this.firstName.toUpperCase(),
            this.lastName.toUpperCase(),
            this.email.toUpperCase(),
            this.phone.toUpperCase()
        ]
        return data
    }
    override get ref(): DocumentReference<DocumentData> {
        return doc(this.collectionRef, this.id)
    }
    override get collectionRef(): CollectionReference<DocumentData> {
        return collection(this._company.ref, "customer")
    }
}