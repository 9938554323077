import { IncommingCall } from "@/classes/IncommingCall"
import { firestore } from "@/firebase"
import { collection, onSnapshot, query, Unsubscribe, where } from "firebase/firestore"
import { Module } from "vuex"
import store from ".."

export interface IncommingCallState {
    incommingCalls: IncommingCall[]
    isLoading: boolean
    firestoreListener: {
        incommingCalls: Unsubscribe | null
    }
}

const module: Module<IncommingCallState, unknown> = {
    state: {
        incommingCalls: [],
        isLoading: true,
        firestoreListener: {
            incommingCalls: null
        }
    },
    actions: {
        loadIncommingCalls({ state }) {
            return new Promise((resolve, reject) => {
                if (state.firestoreListener.incommingCalls) {
                    state.isLoading = false
                    resolve(state.incommingCalls)
                    return
                }

                state.isLoading = true
                
                
                const authid = store.state.auth!.profile!.sub
                const collectionRef = collection(firestore, "incomming_call")
                const queryRef = query (
                    collectionRef,
                    where("userid", "==", authid),
                    where("done", "==", false)
                )
                state.firestoreListener.incommingCalls = onSnapshot(queryRef, (snapshot) => {
                    state.incommingCalls = []
                    for (const doc of snapshot.docs) {
                        const incommingCall = new IncommingCall(doc.id)
                        incommingCall.fromData(doc.data())
                        state.incommingCalls.push(incommingCall)
                    }
                    state.isLoading = false
                    resolve(state.incommingCalls)
                }, (err) => {
                    reject(err)
                })
            })
        }
    }
}
export default module