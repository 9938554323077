
import { auth } from "@/firebase"
import { signOut } from "firebase/auth"
import { defineComponent } from "vue"
import IconItem from "@/components/theme/icon-item.vue"
import AvatarItem from "@/components/theme/avatar-item.vue"
import { mdiLogoutVariant, mdiCog, mdiEmail, mdiAccount } from "@mdi/js"
import { useStore } from "vuex"
import { computed } from "@vue/reactivity"
import { useRouter } from "vue-router"
import LogoItem from "../theme/logo-item.vue"
import { Permission } from "@/classes/EmployeePermission"
    
export default defineComponent({
    setup() {
        const router = useRouter()
        const store = useStore()
    
        const logout = async () => {
            await signOut(auth)
            router.push("/login").catch()
        }
        const openRoute = (url: string) => {
            router.push(url).catch()
        }
    
        const isSignedIn = computed(() => {
            return store.state.auth.isSignedIn
        })
    
        const profilePicture = computed(() => {
            if (!store.state.auth.isSignedIn) {
                return null
            }
            if (!store.state.auth.profile) {
                return null
            }
            return store.state.auth.profile.picture
        })

        const isAdmin = computed(() => {
            if (!store.state.auth.isSignedIn) {
                return false
            }
            if (!store.state.auth.profile) {
                return false
            }

            if (store.state.auth.permission) {
                return store.state.auth?.permission?.permissions?.includes(Permission.CompanyAdmin) || store.state.auth?.permission?.permissions?.includes(Permission.GlobalAdmin)
            }
            return false
        })

        const isGlobalAdmin = computed(() => {
            if (!store.state.auth.isSignedIn) {
                return false
            }
            if (!store.state.auth.profile) {
                return false
            }

            if (store.state.auth.permission) {
                return store.state.auth?.permission?.permissions?.includes(Permission.GlobalAdmin)
            }
            return false
        })
    
        return {
            logout,
            openRoute,
            isSignedIn,
            profilePicture,
            isAdmin,
            mdiLogoutVariant,
            mdiCog,
            isGlobalAdmin,
            mdiEmail,
            mdiAccount
        }
    },
    components: {
        IconItem,
        AvatarItem,
        LogoItem
    }
})
