
import { defineComponent, computed } from "vue"
import AppNavigation from "@/components/app/app-navigation.vue"
import { useStore } from "vuex"
import SnackbarItem from "./components/theme/snackbar-item.vue"
import IconItem from "./components/theme/icon-item.vue"
import AppNotification from "./components/app/app-notification.vue"
import { mdiLoading } from "@mdi/js"


export default defineComponent({
    setup() {

        const store = useStore()
        const isLoading = computed(() => {
            return store.state.auth.isAuthenticating || store.state.isLoading
        })
        const isAuthenticating = computed(() => {
            return store.state.auth.isAuthenticating
        })
        const appClassList = computed(() => {
            const list = ["bg-white"]
            switch (store.state.app.theme) {
                case "business-luchs":
                    list.push("theme-business-luchs")
                default:
                    list.push("theme-florian-thoms")
                    break
            }
            return list
        })
        const contentClassList = computed(() => {
            const list = ["transition-opacity"]
            if (store.state.isLoading) {
                list.push("opacity-50")
            }
            return list
        })

        const showSnackbar = computed({
            get(): boolean {
                return store.state.app.snackbar.visible
            },
            set(newVal: boolean) {
                store.state.app.snackbar.visible = newVal
            }
        })
        const snackbarMessage = computed(() => {
            return store.state.app.snackbar.message
        })
        const snackbarColor = computed(() => {
            return store.state.app.snackbar.color
        })



        return {
            isLoading,
            isAuthenticating,
            appClassList,
            contentClassList,
            showSnackbar,
            snackbarMessage,
            snackbarColor,
            mdiLoading
        }
    },
    components: {
        AppNavigation,
        SnackbarItem,
        AppNotification,
        IconItem
    }
})
