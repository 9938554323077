import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"


const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: () => import(/* webpackChunkName: "home" */ "../views/home-view.vue")
    },
    {
        path: "/user-profile",
        name: "profile",
        component: () => import(/* webpackChunkName: "profile" */ "../views/profile-view.vue")
    },
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "../views/login-view.vue")
    },
    {
        path: "/objects/:filter",
        name: "objects",
        component: () => import(/* webpackChunkName: "objects" */ "../views/objects-view.vue")
    },
    {
        path: "/object/:objectID",
        name: "object",
        component: () => import(/* webpackChunkName: "object" */ "../views/object-view.vue")
    },
    {
        path: "/customers",
        name: "customers",
        component: () => import(/* webpackChunkName: "customers" */ "../views/customers-list-view.vue")
    },
    {
        path: "/customer/:customerid",
        name: "customer",
        component: () => import(/* webpackChunkName: "customer" */ "../views/customer-view.vue")
    },
    {
        path: "/calendar",
        name: "calendar",
        component: () => import(/* webpackChunkName: "calendar" */ "../views/calendar-view.vue")
    },
    {
        path: "/interested",
        name: "interested-list",
        component: () => import(/* webpackChunkName: "interested-list" */ "../views/interested-list-view.vue")
    },
    {
        path: "/interested/:interestedID",
        name: "interested",
        component: () => import(/* webpackChunkName: "interested" */ "../views/interested-view.vue")
    },
    {
        path: "/manage/portal_customers",
        name: "manage_portal_customers",
        component: () => import(/* webpackChunkName: "manage_portal_customers" */ "../views/manage/portal-customers-view.vue")
    },
    {
        path: "/manage/portal_customer/:customerID",
        name: "manage_portal_customer",
        component: () => import(/* webpackChunkName: "manage_portal_customer" */ "../views/manage/portal-customer-view.vue")
    },
    {
        path: "/manage/category_templates",
        name: "manage_category_templates",
        component: () => import(/* webpackChunkName: "manage_category_templates" */ "../views/manage/category-templates-view.vue")
    },
    {
        path: "/manage/email_templates",
        name: "manage_email_templates",
        component: () => import(/* webpackChunkName: "manage_email_templates" */ "../views/manage/email-templates-view.vue")
    },
    {
        path: "/manage/user_management",
        name: "user_management",
        component: () => import(/* webpackChunkName: "user_management_view" */ "../views/manage/user-management-view.vue")
    },
    {
        path: "/manage/affiliatelinkold_management",
        name: "affiliatelinkold_management",
        component: () => import(/* webpackChunkName: "affiliatelinkold_management" */ "../views/manage/alold-management-view.vue")
    },
    {
        path: "/manage/email_management",
        name: "email_management",
        component: () => import(/* webpackChunkName: "email_management" */ "../views/manage/emails-view.vue")
    },
    {
        path: "/manage/migration",
        name: "migration",
        component: () => import(/* webpackChunkName: "migration" */ "../views/manage/migration-view.vue")
    },
    {
        path: "/manage/overview",
        name: "overview",
        component: () => import(/* webpackChunkName: "overview" */ "../views/manage/overview-view.vue")
    },
    {
        path: "/manage/faqs",
        name: "faqs",
        component: () => import(/* webpackChunkName: "faqs" */ "../views/manage/faqs-view.vue")
    },
    {
        path: "/manage/logs",
        name: "logs",
        component: () => import(/* webpackChunkName: "logs" */ "../views/manage/logs-view.vue")
    },
    {
        path: "/manage/mails",
        name: "mails",
        component: () => import(/* webpackChunkName: "mails" */ "../views/manage/mails-view.vue")
    },
    {
        path: "/manage/epcasedata/:caseid",
        name: "epcasedata",
        component: () => import(/* webpackChunkName: "epcasedata" */ "../views/manage/epcasedata-view.vue")
    },
    {
        path: "/error",
        name: "error",
        component: () => import(/* webpackChunkName: "error" */ "../views/error-view.vue")
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior: () => {
        return { top: 0 }
    }
})

export default router
