import { collection, CollectionReference, doc, DocumentData, DocumentReference } from "firebase/firestore"
import { Company } from "./Company"
import { FirestoreClass } from "./FirestoreClass"

export class EmployeeCustomerPortalData {
    isSalesPartner: boolean = false
    salespartnersIWorkFor: string[] = []

    constructor(data: Record<string, unknown> | null = null) {
        this.isSalesPartner = data?.isSalesPartner as boolean || false
        this.salespartnersIWorkFor = data?.salespartnersIWorkFor as string[] || []
    }
    
    toData() {
        return {
            isSalesPartner: this.isSalesPartner,
            salespartnersIWorkFor: this.salespartnersIWorkFor
        }
    }
}

export class Address {
    street: string = ""
    number: string = ""
    postcode: string = ""
    location: string = ""
    room: string = ""

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.street = data.street as string || ""
            this.number = data.number as string || ""
            this.postcode = data.postcode as string || ""
            this.location = data.location as string || ""
            this.room = data.room as string || ""
        }
    }
    toData() {
        return {
            street: this.street,
            number: this.number,
            postcode: this.postcode,
            location: this.location,
            room: this.room

        }
    }
}

export enum EmployeeRole {
    SalesPartner = "SalesPartner",
    Assistant = "Assistant"
}

export type WorkTime = ({ start: { hour: number, minute: number }, end: { hour: number, minute: number } } | null)
export class Employee extends FirestoreClass {
    private _company: Company

    active:boolean = true
    customerPortal: EmployeeCustomerPortalData | null = null
    picture: string = ""
    firstName: string = ""
    lastName: string = ""
    email: string = ""
    phone: string = ""
    mobile: string = ""
    whatsApp: string = ""
    title: string = ""
    homepage: string = ""
    address: Address = new Address()
    salutation: string = ""
    role: string = ""
    assistants: string[] = []
    assistantOf: string[] = []
    representatives: string[] = []
    representativeOf: string[] = []
    lastUpdate: Date = new Date()
    vermittlerregisternummer: string = ""
    useSignatureInBC: boolean = false

    workTime: WorkTime[] = []
    workAddresses: (Address | null)[] = []


    constructor(company: Company, id: string = "") {
        super(id)
        this._company = company
    }

    get company() {
        return this._company
    }

    get fullName() {
        return `${this.firstName} ${this.lastName}`.trim()
    }

    override fromData(data: Record<string, unknown>): void {
        
        this.active = (typeof data.active === "boolean") ? data.active : true
        this.picture = data.picture as string ?? ""
        this.firstName = data.firstName as string ?? ""
        this.lastName = data.lastName as string ?? ""
        this.email = data.email as string ?? ""
        this.phone = data.phone as string ?? ""
        this.mobile = data.mobile as string ?? ""
        this.whatsApp = data.whatsApp as string ?? ""
        this.title = data.title as string ?? "Finanzexperte"
        this.homepage = data.homepage as string ?? ""
        this.salutation = data.salutation as string ?? ""
        this.vermittlerregisternummer = data.vermittlerregisternummer as string ?? ""
        this.role = data.role as EmployeeRole ?? EmployeeRole.SalesPartner
        this.assistants = data.assistants as string[] ?? []
        this.assistantOf = data.assistantOf as string[] ?? []
        this.representatives = data.representatives as string[] ?? []
        this.representativeOf = data.representativeOf as string[] ?? []
        this.useSignatureInBC = data.useSignatureInBC as boolean ?? false
        
        if (data.address) {
            this.address = new Address(data.address as Record<string, unknown>)
        }

        if (data.customerPortal) {
            this.customerPortal = new EmployeeCustomerPortalData(data.customerPortal as Record<string, unknown>)
        }

        if (data.workTime) {
            this.workTime = data.workTime as WorkTime[]
        } else {
            this.workTime = [
                null,
                { start: { hour: 9, minute: 0 }, end: { hour: 18, minute: 0 } },
                { start: { hour: 9, minute: 0 }, end: { hour: 18, minute: 0 } },
                { start: { hour: 9, minute: 0 }, end: { hour: 18, minute: 0 } },
                { start: { hour: 9, minute: 0 }, end: { hour: 18, minute: 0 } },
                { start: { hour: 9, minute: 0 }, end: { hour: 18, minute: 0 } },
                { start: { hour: 9, minute: 0 }, end: { hour: 18, minute: 0 } }
            ]
        }
        if (data.workAddresses) {
            this.workAddresses = []
            for (const address of data.workAddresses as (Record<string, unknown> | null)[]) {
                if (address) {
                    this.workAddresses.push(new Address(address))
                } else {
                    this.workAddresses.push(null)
                }
            }
        } else {
            this.workAddresses = [
                null,
                null,
                null,
                null,
                null,
                null,
                null
            ]
        }
    }

    override toData() {
        const data: Record<string, unknown> = {}
        data.active = this.active
        data.picture = this.picture
        data.firstName = this.firstName
        data.lastName = this.lastName
        data.email = this.email
        data.phone = this.phone
        data.mobile = this.mobile
        data.whatsApp = this.whatsApp
        data.title = this.title
        data.homepage = this.homepage
        data.vermittlerregisternummer = this.vermittlerregisternummer
        data.address = this.address.toData()
        data.salutation = this.salutation
        data.role = this.role
        data.assistants = this.assistants
        data.assistantOf = this.assistantOf
        data.representatives = this.representatives
        data.representativeOf = this.representativeOf
        data.useSignatureInBC = this.useSignatureInBC
        data.lastUpdate = new Date()

        if (this.customerPortal) {
            data.customerPortal = this.customerPortal.toData()
        }

        if (this.workTime) {
            data.workTime = this.workTime
        }
        if (this.workAddresses) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const workAddresses: any[]  = []
            for (const address of this.workAddresses) {
                if (address) {
                    workAddresses.push(address.toData())
                } else {
                    workAddresses.push(null)
                }
            }
            data.workAddresses = workAddresses
        }
        
        return data
    }

    override get ref(): DocumentReference<DocumentData> {
        return doc(this.collectionRef, this.id)
    }
    override get collectionRef(): CollectionReference<DocumentData> {
        return collection(this._company.ref, "employee")
    }
}
