import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid gap-4 fixed bottom-0 sm:bottom-6 w-full sm:w-96 right-0 sm:right-10 z-50"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppNotificationItem = _resolveComponent("AppNotificationItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    ((_ctx.displayedCalls.length > 0))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedCalls, (call) => {
            return (_openBlock(), _createBlock(_component_AppNotificationItem, {
              key: call.uniqueid,
              call: call
            }, null, 8, ["call"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}