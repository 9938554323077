
import { IncommingCall } from "@/classes/IncommingCall"
import { computed } from "@vue/reactivity"
import { ComputedRef, defineComponent } from "vue"
import { useStore } from "vuex"
import AppNotificationItem from "./app-notification-item.vue"

export default defineComponent({
    setup() {
        const store = useStore()
        const displayedCalls: ComputedRef<IncommingCall[]> = computed(() => {
            if(!store.state.user?.user?.useTK){
                return []
            }
            
            const items: IncommingCall[] = []
            for (let incommingCall of store.state.incommingCall.incommingCalls) {
                if (incommingCall.state == "RINGING" || incommingCall.state == "ANSWERED") {
                    items.push(incommingCall)
                }
            }
            return items
        })

        return {
            displayedCalls
        }
    },
    components: {
        AppNotificationItem
    }
})
